export const FLAGS = {
  D2C_CHILD_BOOKING_FLOW: 'd2c-child-booking-flow',
  SELECT_STATE_IMAGE: 'select-state-image',
  SHOW_WEIGHT_LOSS_ACCORDION: 'show-weight-loss-accordion',
  ENABLED_STRUCTURED_VISIT_REASON_PAGE: 'enable-structured-visit-reason-page',
  UPDATE_LAB_PRICING: 'update-lab-pricing',
  SENTRY_FEEDBACK_WIDGET: 'sentry-feedback-widget',
  APPOINTMENTS_BY_DOCTOR: 'appointment-times-by-doctor',
  MONEY_BACK_GUARANTEE_STYLE_UPDATE: 'money-back-guarantee-style-update',
  PREFERRED_NAME_STATUS: 'preferred_name_prefill_blank',
  SHOW_THERAPY_EMERGENCY_TRIAGE: 'show_therapy_flow_questionnaire',
  HARD_MEMBERSHIP: 'hard-membership',
  PATIENT_BILLING_NOTICE_UPDATES: 'patient-billing-notice-updates',
  OON_MODAL_UPDATES: 'oon-modal-updates',
  MEDICARE_UPDATES: 'medicare_updates',
  INIT_FULLSTORY_DIRECTLY: 'initialize_fullstory_directly',
  DISABLE_DEPENDENT_INSURANCE: 'disable-dependent-insurance',
  PROFILE_PAGE_MULTIPLE_COVERAGES: 'profile-page-multiple-coverages',
};
